document.addEventListener('turbolinks:load', () => {
    let fileUploaders = document.querySelectorAll('.js-file-uploader');

    if (fileUploaders) {
        fileUploaders.forEach(fileUploader => {
            fileUploader.addEventListener('change', () => {
                let fileUploaderInput = fileUploader.querySelector('input[type="file"]')
                let fileUploaderSpan = fileUploader.querySelector('span');

                if (fileUploaderSpan && fileUploaderInput) {
                    fileUploaderSpan.innerHTML = fileUploaderInput.value.split('\\').pop();
                }
            })
        })
    }
})