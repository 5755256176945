document.addEventListener('turbolinks:load', () => {
    let submitTriggers = document.querySelectorAll('.js-submit');

    if (submitTriggers) {
        submitTriggers.forEach(submitTrigger => {
            submitTrigger.addEventListener('change', () => {
                let formTag = submitTrigger.closest('form');

                if (formTag) {
                    formTag.submit();
                }
            })
        })
    }
})