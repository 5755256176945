document.addEventListener('turbolinks:load', () => {
    let stickyNavigation = document.querySelector('.js-sticky-navigation');

    function setStickyNavigationClasses() {
        let scrollPosition = document.documentElement.scrollTop;
        if (scrollPosition <= stickyNavigation.offsetHeight) {
            stickyNavigation.classList.remove('is-scrolling')
            stickyNavigation.classList.add('is-on-top');
        } else {
            stickyNavigation.classList.add('is-scrolling')
            stickyNavigation.classList.remove('is-on-top');
        }
    }

    if (stickyNavigation) {
        setStickyNavigationClasses();

        window.onscroll = () => {
            setStickyNavigationClasses();
        }
    }
})