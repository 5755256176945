document.addEventListener('turbolinks:load', () => {
    let rowHeaders = document.querySelectorAll('.js-row-header');
    // let rowContents = document.querySelectorAll('.js-row-content');

    if (rowHeaders) {
        rowHeaders.forEach(rowHeader => {
            rowHeader.addEventListener('click', () => {
                rowHeader.classList.toggle('is-active');

                let rowContent = document.querySelector('.js-row-content[data-index="' + rowHeader.dataset.index + '"]');

                if (rowContent) {
                    rowContent.classList.toggle('is-active');
                }
            })
        })
    }
})