document.addEventListener('turbolinks:load', () => {
    let moneyInputs = document.querySelectorAll('.js-money-input');
    // let moneyOutputs = document.querySelectorAll('.js-money-output');

    if (moneyInputs) {
        moneyInputs.forEach(moneyInput => {
            ['propertychange', 'change', 'click', 'keyup', 'input', 'paste'].forEach(event => {
                moneyInput.addEventListener(event, () => {
                    let moneyOutput = document.querySelector('.js-money-output[data-id="' + moneyInput.dataset.id + '"]');

                    if (moneyOutput) {
                        if (moneyInput.value > 0) {
                            let value = parseInt(moneyInput.value).toString();
                            let cent = value.substring(value.length - 2, value.length);
                            if (value.length <= 1) {
                                cent = '0' + cent;
                            }
                            
                            ;
                            
                            let baseValue = value.substring(0, value.length - 2);
                            if (baseValue.length <= 0) {
                                baseValue = "00";
                            }
                            
                            let output = '';
                            let baseSegments = baseValue.split("").reverse().join("").match(/.{1,3}/g).reverse();
                            baseSegments.forEach((segment, index) => {
                                output += segment.split("").reverse().join("");
                                if (index + 1 < baseSegments.length) {
                                    output += '.';
                                }
                            });
                            
                            moneyOutput.innerHTML = output + ',' + cent;
                        } else {
                            moneyOutput.innerHTML = "0,00"
                        }
                    }
                });
            });

            moneyInput.dispatchEvent(new Event('change'));
        })
    }
})