document.addEventListener('turbolinks:load', () => {
    let burgerTrigger = document.querySelector('.js-burger-trigger');
    let burgerMenu = document.querySelector('.js-burger-menu');

    if (burgerTrigger && burgerMenu) {
        burgerTrigger.addEventListener('click', () => {
            burgerTrigger.classList.toggle('is-active');
            burgerMenu.classList.toggle('is-active');
        });
    }
})